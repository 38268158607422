import Pagination from '@rsa-digital/evo-shared-components/components/Pagination';
import { usePagination } from '@rsa-digital/evo-shared-components/components/Pagination/usePagination';
import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import Hero, { HeroData } from 'components/Hero';
import Layout from 'components/Layout';
import { CtaBannerData } from 'components/blocks/CtaBanner';
import FeaturedArticles, { FeaturedArticleData } from 'components/blocks/FeaturedArticles';
import ListedArticles, { ListedArticlesData } from 'components/blocks/ListedArticles';
import { processPageMeta } from 'helpers/csTypeProcessors';
import { trackPaginationEvent } from 'helpers/eventTracking';
import { useAnalyticsPageVariables } from 'helpers/useAnalyticsPageVariables';
import { PageMeta } from 'types/contentStack';
import { StyledCtaBanner } from './styles';

type NewsIndexPageProps = {
  data: NewsIndexPageData;
};

const NewsIndexPage: React.FC<NewsIndexPageProps> = ({ data }) => {
  const page = data.csNewsIndexPage;

  const { meta, breadcrumbs } = processPageMeta(page.page_meta, page.url);
  useAnalyticsPageVariables(
    {
      pageCategories: breadcrumbs.parent_links.map((link) => link.page_name),
      pageType: 'NewsIndexPage',
    },
    []
  );

  const { currentPage, totalPages, setCurrentPage, shownItems } = usePagination(
    page.listed_articles.listed_articles,
    page.listed_articles.articles_per_page
  );

  const setCurrentPageWithTracking = (newPage: number, trackingLinkUsed: string): void => {
    trackPaginationEvent(totalPages, trackingLinkUsed, newPage, currentPage)();
    setCurrentPage(newPage);
  };

  return (
    <>
      <Helmet script={[helmetJsonLdProp(JSON.parse(page.json_ld))]} />
      <Layout
        pageType="brochureware"
        meta={meta}
        breadcrumbs={breadcrumbs}
        complianceText={page.footer_compliance ?? undefined}
        showStickyHeader={page.show_sticky_header ?? undefined}
        showAlertNotification={page.show_alert_notification ?? undefined}>
        <Hero hero={page.hero} />
        <FeaturedArticles featured_articles={page.featured_articles} />
        <ListedArticles listedArticles={shownItems} />
        <StyledCtaBanner cta_banner={page.cta_banner} />
        {totalPages > 1 && (
          <Pagination
            data-cy="Pagination"
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPageWithTracking}
          />
        )}
      </Layout>
    </>
  );
};
export default NewsIndexPage;

type NewsIndexPageData = {
  csNewsIndexPage: {
    title: string;
    url: string;
    hero: HeroData;
    featured_articles: FeaturedArticleData[];
    listed_articles: ListedArticlesData;
    cta_banner: CtaBannerData;
    page_meta: PageMeta;
    json_ld: string;
    footer_compliance: string | null;
    show_sticky_header: boolean | null;
    show_alert_notification: boolean | null;
  };
};

export const query = graphql`
  query($id: String!) {
    csNewsIndexPage(id: { eq: $id }) {
      title
      url
      ...HeroNewsIndex
      footer_compliance
      show_sticky_header
      show_alert_notification
      ...FeaturedArticlesBlockNewsIndex
      ...ListedArticlesBlockNewsIndex
      ...CtaBannerBlockNewsIndex
      page_meta {
        ...BreadcrumbsNewsIndex
        ...MetaDataTagsNewsIndex
      }
      json_ld
    }
  }
`;
