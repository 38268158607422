import { SimpleDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import ListedArticle from '@rsa-digital/evo-shared-components/components/ListedArticle';
import { graphql } from 'gatsby';
import React from 'react';
import { processMandatoryCta, processMandatoryImageAsset } from 'helpers/csTypeProcessors';
import { CsAsset, CsCta } from 'types/contentStack';

export type ListedArticlesData = {
  articles_per_page: number;
  listed_articles: ListedArticleData[];
};

export type ListedArticleData = {
  image: CsAsset;
  header_text: string;
  read_time?: string;
  preview_text: string;
  cta: [CsCta];
};

type ListedArticleProps = {
  listedArticles: ListedArticleData[];
};

export const query = graphql`
  fragment ListedArticlesBlockNewsIndex on cs__news_index_page {
    listed_articles {
      articles_per_page
      listed_articles {
        image {
          ...CsAsset
        }
        header_text
        read_time
        preview_text
        cta {
          display_text
          screen_reader_text
          url
          open_in_new_tab
        }
      }
    }
  }
`;

const ListedArticlesBlock: React.FC<ListedArticleProps> = ({ listedArticles }) => {
  const shownArticles = listedArticles.map(
    ({ header_text, read_time, preview_text, cta, image }, index: number) => (
      <>
        <ListedArticle
          data-cy={`ListedArticle${index}`}
          key={header_text}
          title={header_text}
          readTime={read_time}
          previewText={preview_text}
          cta={processMandatoryCta(cta, 'Body')}
          image={processMandatoryImageAsset(image)}
        />
        <SimpleDivider />
      </>
    )
  );

  return <>{shownArticles}</>;
};

export default React.memo(ListedArticlesBlock);
